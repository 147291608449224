<script setup lang="ts">
import EqZaimIcon from "~/components/icons/eqZaimIcon.vue";
import { NuxtLink } from "#components";

defineProps({
  title: {
    type: String,
    required: true,
    default: "",
  },
});

let pageRating = useCookie<number>("pageRating");

const ratings = [
  {
    class: "text-state-error",
  },
  {
    class: "text-secondary-orange",
  },
  {
    class: "text-secondary-yellow-300",
  },
  {
    class: "text-secondary-yellow-200",
  },
  {
    class: "text-primary-aqua",
  },
];

const setRating = (rating: number) => {
  pageRating.value = rating;
};
</script>

<template>
  <div class="rating">
    <div class="rating__title" v-text="title"></div>
    <div class="rating__stars">
      <template v-for="(rating, index) in ratings">
        <div
          @click.prevent="!pageRating ? setRating(index) : null"
          :class="{ 'cursor-pointer': !pageRating }"
        >
          <EqZaimIcon
            icon-name="star2"
            :class="
              !pageRating || pageRating < index
                ? 'text-secondary-gray-50'
                : rating.class
            "
            class="text-[32px]"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.rating {
  @apply container mx-auto flex flex-col gap-16px py-16px
  md:py-32px md:items-center;

  &__title {
    @apply text-title-S
    md:text-center md:text-heading-XXS
    xl:text-title-XL;
  }

  &__stars {
    @apply flex gap-[2px];
  }
}
</style>
